
import { Vue, Options } from 'vue-class-component';
import QRCodeStyling, {
  DrawType,
  TypeNumber,
  Mode,
  ErrorCorrectionLevel,
  DotType,
  CornerSquareType,
  CornerDotType,
  Extension
} from 'qr-code-styling';

@Options({
  mounted : function() {
    this.qrCode.append(this.$refs["qrCode"]);
  },
  watch: {
    ['options.data']: function() {
      this.qrCode.update(this.options);
    }
  },
  methods: {
 onImageChange(e: Event) { // Specifying the type for e
    const input = e.target as HTMLInputElement; // Type assertion for e.target
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => { // Specifying the type for e
        if (e.target) {
          this.options.image = e.target.result as string; // Type assertion for e.target.result
          this.qrCode.update(this.options);
        }
      };
      reader.readAsDataURL(input.files[0]);
    }
  },
    download() {
      this.qrCode.download({ extension: this.extension as Extension })
    }
  },
  data: () => {
    const options = {
      width: 300,
      height: 300,
      type: 'svg' as DrawType,
      data: 'https://transcendencenft.com/',
      image: '/favicon.ico',
      margin: 10,
      qrOptions: {
        typeNumber: 0 as TypeNumber,
        mode: 'Byte' as Mode,
        errorCorrectionLevel: 'Q' as ErrorCorrectionLevel
      },
      imageOptions: {
        hideBackgroundDots: true,
        imageSize: 0.4,
        margin: 20,
        crossOrigin: 'anonymous',
      },
      dotsOptions: {
        color: '#9b51e0',
        // gradient: {
        //   type: 'linear', // 'radial'
        //   rotation: 0,
        //   colorStops: [{ offset: 0, color: '#8688B2' }, { offset: 1, color: '#77779C' }]
        // },
        type: 'rounded' as DotType
      },
      backgroundOptions: {
        color: '#ffffff',
        // gradient: {
        //   type: 'linear', // 'radial'
        //   rotation: 0,
        //   colorStops: [{ offset: 0, color: '#ededff' }, { offset: 1, color: '#e6e7ff' }]
        // },
      },
      cornersSquareOptions: {
        color: '#35495E',
        type: 'extra-rounded' as CornerSquareType,
        // gradient: {
        //   type: 'linear', // 'radial'
        //   rotation: 180,
        //   colorStops: [{ offset: 0, color: '#25456e' }, { offset: 1, color: '#4267b2' }]
        // },
      },
      cornersDotOptions: {
        color: '#35495E',
        type: 'dot' as CornerDotType,
        // gradient: {
        //   type: 'linear', // 'radial'
        //   rotation: 180,
        //   colorStops: [{ offset: 0, color: '#00266e' }, { offset: 1, color: '#4060b3' }]
        // },
      }
    };
    return {
      options,
      extension: 'svg',
      qrCode: new QRCodeStyling(options)
    }
  }
})
export default class QRCodeStylingComponent extends Vue {}
